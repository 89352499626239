import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { PageLayout, Seo } from '../components'

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h1,
  h2 {
    font-family: Koulen;
    margin: 0;
  }
  > p {
    margin-top: 0;
    max-width: 625px;
    text-align: center;
  }
  > address {
    text-align: center;
    > a {
      font-family: Roboto;
      font-weight: 700;
      text-decoration: none;
      color: black;
      :visited {
        color: black;
      }
      :hover {
        text-decoration: underline;
      }
    }
  }
`
const EmailContainer = styled.div`
  margin-top: 0.875rem;
`
const OperationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  padding: 1rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 1px 1px 8px grey;
`
const DayContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-content: space-between;
  > p {
    margin-top: 0;
  }
`
const Day = styled.p`
  font-weight: 700;
  justify-self: start;
`

const Hours = styled.p`
  justify-self: end;
`
interface Props {
  data: {
    contact: {
      address: {
        street: string
        city: string
        state: string
        zip: string
      }
      operation: {
        monday: string
        tuesday: string
        wednesday: string
        thursday: string
        friday: string
        saturday: string
        sunday: string
      }
      phone: string
      email: string
      blurb: string
    }
  }
}
const ContactPage: React.FC<Props> = ({ data }) => {
  const addressData = data.contact.address
  const contactData = {
    phone: data.contact.phone,
    email: data.contact.email,
  }
  const operation = data.contact.operation
  return (
    <Seo title="Contact">
      <PageLayout>
        <AddressContainer>
          <h1>Contact and Location</h1>
          <p>{data.contact.blurb}</p>
          <h2>Address</h2>
          <address>
            <p>
              {addressData.street}
              <br />
              {addressData.city}, {addressData.state} {addressData.zip}
            </p>
            <a href={`tel:1-${contactData.phone}`}>{contactData.phone}</a>
          </address>
          {contactData.email ? (
            <EmailContainer>
              <p>
                You can also send us an{' '}
                <a href={`mailto:${contactData.email}`}>email</a>.
              </p>
            </EmailContainer>
          ) : null}
        </AddressContainer>

        <OperationContainer>
          <h3>Hours of Operation</h3>
          <ScheduleContainer>
            {Object.entries(operation).map(([key, value]) => {
              return (
                <DayContainer key={key}>
                  <Day>{key.replace(/./, (c) => c.toUpperCase())}:</Day>
                  <Hours>{value}</Hours>
                </DayContainer>
              )
            })}
          </ScheduleContainer>
        </OperationContainer>
      </PageLayout>
    </Seo>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query {
    contact: sanityContact {
      address {
        city
        state
        street
        zip
      }
      blurb
      email
      operation {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      phone
    }
  }
`
